var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pt-3" },
    [
      !_vm.isSubmitted && !_vm.isCancelled
        ? _c(
            "a-row",
            { attrs: { gutter: [16, 16], type: "flex" } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "danger", icon: "delete" },
                          on: { click: _vm.handleDeleteRow }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_delete_row")) + " ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", icon: "plus" },
                          on: { click: _vm.handleAddRow }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_add_row")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            [
              _c(
                "a-table",
                {
                  attrs: {
                    "data-source": _vm.dtSource,
                    "row-selection": {
                      selectedRowKeys: _vm.selectedRowKeys,
                      onChange: _vm.onSelectChange
                    },
                    scroll: { x: 1500 }
                  }
                },
                [
                  _c(
                    "a-table-column",
                    {
                      key: "productCode",
                      attrs: { "data-index": "productCode" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _c("CSelectmasterProductCode", {
                                on: {
                                  "on-select": function(e) {
                                    return _vm.onSelectProduct(
                                      e,
                                      record,
                                      "code"
                                    )
                                  }
                                },
                                model: {
                                  value: record.productCode,
                                  callback: function($$v) {
                                    _vm.$set(record, "productCode", $$v)
                                  },
                                  expression: "record.productCode"
                                }
                              })
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_product_code")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "productName",
                      attrs: { "data-index": "productName" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _c("CSelectmasterProductName", {
                                on: {
                                  "on-select": function(e) {
                                    return _vm.onSelectProduct(
                                      e,
                                      record,
                                      "name"
                                    )
                                  }
                                },
                                model: {
                                  value: record.productName,
                                  callback: function($$v) {
                                    _vm.$set(record, "productName", $$v)
                                  },
                                  expression: "record.productName"
                                }
                              })
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_product_name")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "qty",
                      attrs: { "data-index": "qty", width: 120 },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _c("a-input-number", {
                                staticStyle: { width: "100%" },
                                attrs: { min: 0 },
                                on: { change: _vm.onchangeQty },
                                model: {
                                  value: record.qty,
                                  callback: function($$v) {
                                    _vm.$set(record, "qty", $$v)
                                  },
                                  expression: "record.qty"
                                }
                              })
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_qty")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "uom",
                      attrs: { "data-index": "uom", width: 150 },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _c("CSelectUomConv", {
                                attrs: { "prop-product-id": record.productId },
                                on: { "on-select": _vm.onselectUom },
                                model: {
                                  value: record.uomId,
                                  callback: function($$v) {
                                    _vm.$set(record, "uomId", $$v)
                                  },
                                  expression: "record.uomId"
                                }
                              })
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_uom")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "batchNumber",
                      attrs: { "data-index": "batchNumber" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _c("span", { staticClass: "ant-form-text" }, [
                                _vm._v(
                                  _vm._s(
                                    record.batchNumber ||
                                      _vm.$t(
                                        "lbl_batch_number_generate_after_submit"
                                      )
                                  )
                                )
                              ])
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_batch_number")))
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }