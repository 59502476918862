





































































































import { RESPONSE_STOCK_TYPE, STATE_STOCK_ADJUSTMENT } from "@/models/enums/stock-adjustment.enum";
import { ResponseProduct } from "@interface/product.interface";
import { ResponseStockAdjustment } from "@interface/stock-adjustment.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import Vue from "vue";
import { letterKeydown } from "@/validator/globalvalidator";
import { Mode } from "@/models/enums/global.enum";
import { ResponseListMasterType } from "@interface/master.interface";
import { masterTypeService } from "@service/master-type.service";
import { IOption } from "@interface/common.interface";
import MNotificationVue from "@/mixins/MNotification.vue";

export interface AdjustmentTableRow {
  key: any;
  productId: string;
  productCode: string;
  productName: string;
  qty: number;
  priceType: string;
  uom: string;
  uomId: string;
  id: string;
  batchId: string;
  batchNumber: string;
}

export default Vue.extend({
  name: "TableStockAdjustment",
  components: {
    CSelectmasterProductCode: () => import(/*webpackPrefetch: true*/"@/components/shared/select-master-product-code/CSelectMasterProductCode.vue"),
    CSelectmasterProductName: () => import(/*webpackPrefetch: true*/"@/components/shared/select-master-product-name/CSelectMasterProductName.vue"),
    CSelectUomConv: () => import(/*webpackPrefetch: true*/"@/components/shared/select-uom-conversions/CSelectUomConv.vue"),
  },
  mixins: [
    MNotificationVue
  ],
  props: {
    detailReports: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      dtSource: [] as AdjustmentTableRow[],
      selectedRowKeys: [] as number[],
      loading: {
        product: false,
        generate: false,
        price: false,
      },
      optPrice: [] as IOption[],
    };
  },
  computed: {
    isSubmitted(): boolean {
      return this.detailReports.state === STATE_STOCK_ADJUSTMENT.SUBMITTED;
    },
    isCancelled(): boolean {
      return this.detailReports.state === STATE_STOCK_ADJUSTMENT.CANCELLED;
    },
    isDraft(): boolean {
      return this.detailReports.state === STATE_STOCK_ADJUSTMENT.DRAFT;
    },
    isModeCreate(): boolean {
      return this.$route.meta.mode === Mode.CREATE;
    }
  },
  watch: {
    detailReports(): void {
      this.fillTable();
    }
  },
  mounted() {
    if (this.detailReports) this.fillTable();
  },
  deactivated() {
    if (!this.isSubmitted && !this.isCancelled && !this.isDraft) this.dtSource = [];
  },
  methods: {
    letterKeydown,
    getListMasterType(params: RequestQueryParamsModel): Promise<ResponseListMasterType[]> {
      return masterTypeService.listMaster(params);
    },
    onSelectChange(selectedRowKeys: number[]) {
      this.selectedRowKeys = selectedRowKeys;
    },
    emitDataUpdate(): void {
      this.$emit("onDataUpdate", { name: "adjustment", data: this.dtSource, deletedRow: (this.detailReports?.id ? this.selectedRowKeys : []) });
    },
    handleDeleteRow(): void {
      const { dtSource } = this;
      this.dtSource = dtSource.filter((row: AdjustmentTableRow) => {
        return !this.selectedRowKeys.includes(row.key);
      });
      this.dtSource.forEach((data, index) => data.key = index);
      this.dtSource = this.dtSource.slice();
      this.emitDataUpdate();
      this.selectedRowKeys = [];
    },
    /**
     * @param e uom id
     */
    onselectUom(e: string): void {
      this.emitDataUpdate();
    },
    /**
     * @param e price
     */
    onselectPrice(e: string): void {
      this.emitDataUpdate();
    },
    onchangeQty(): void {
      this.emitDataUpdate();
    },
    onSelectProduct(e: {value: string, meta: IOption<ResponseProduct>}, record: AdjustmentTableRow, source: "code" | "name"): void {
      if (source === "code") {
        record.productName = e.meta.meta?.name || "";
      } else {
        record.productCode = e.meta.meta?.code || "";
      }
      record.productId = e.meta.meta?.id || "";
      this.emitDataUpdate();
    },
    fillTable(): void {
      const detail: ResponseStockAdjustment = {...this.detailReports};
      let { dtSource } = this;
      if (
        detail.type === RESPONSE_STOCK_TYPE.STOCK_ADJUSTMENT &&
        detail.stockAdjustmentLines &&
        detail.stockAdjustmentLines.length
      ) {
        dtSource = [];
        detail.stockAdjustmentLines.forEach((item, i) => {
          dtSource.push({
            key: i,
            productId: item.product.id,
            productCode: item.product.code,
            productName: item.product.name,
            uom: item.uom.unit,
            uomId: item.uom.id,
            id: item.id,
            batchId: item.batchNumberId,
            batchNumber: item.batchNumber,
            priceType: item.priceType,
            qty: item.qty,
          });
        });

        this.dtSource = dtSource;
      }
    },
    handleAddRow(): void {
      const { dtSource } = this;
      const newRow: AdjustmentTableRow = {
        key: dtSource.length,
        productId: "",
        productCode: "",
        productName: "",
        qty: 0,
        priceType: "",
        uom: "",
        uomId: "",
        id: "",
        batchId: "",
        batchNumber: "",
      };
      this.dtSource = [...dtSource, newRow];
    },
  }
});
